.components-app-footer {
  background-color: #333941;
  width: 100%;
  margin-top: 40px;
  color: white;
  .footer-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-around;
    h2 {
      margin: 0;
      font-weight: 700;
      margin-top: 0.5rem;
      font-size: 1.25rem;
    }

    .section {
      margin-right: 40px;
      display: flex;
      flex-direction: column;
    }
  }
  a {
    color: white;
    font-size: 0.875rem;

    &:hover {
      color: white !important;
    }
  }
}