.components-company-admin-companies {
  .search-and-new-button {
    display: flex;

    margin-bottom: 1rem;

    input {
      width: 500px
    }

    button {
      margin-left: auto
    }
  }
}