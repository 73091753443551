@import '../common/skeleton/common.scss';

$fontSize: 2rem;

.provide-signee-info {
  &.skeleton {
    h1 {
      width: 50%;
      height: $fontSize;

      @include skeleton-style();
    }

    p {
      height: 1.3125rem;
      @include skeleton-style();
    }
  }
}