.travel-card-details {

  dl {
    .label {
      flex-basis: 15%;
      margin-bottom: 0 !important;
      max-width: none;
    }

    .value {
      flex-basis: 80%;
      margin-bottom: 0 !important;
    }
  }

}