.components-freeticketagreement-invoicebasedetails {
  display: flex;
  flex-direction: column;
 
  .details {
    max-width: 350px;
    margin-bottom: 1rem;
  }
  .right-align {
    text-align: right;
  }
  .download {
    margin-left: auto;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}