.components-company-tickets-page {
  .search-and-new-ticket {
    display: flex;

    input {
      width: 440px;
    }

    button {
      margin-left: auto;
    }
  }

  table {
    margin-top: 1.5rem;

    tr {
      cursor: pointer;
    }

    th {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .pagination {
    margin-top: 20px;
  }

  @media only screen and (max-width: 700px) {
    .search-and-new-ticket {
      flex-direction: column;

      button {
        width: 100%;
        margin-top: 10px;
      }

      input {
        width: 100%;
      }
    }
  }
}
