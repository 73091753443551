@import '../../../node_modules/@ruter-ds/rds-scss-core/src/scss/00-vendor/manifest-vendor';
@import '../../../node_modules/@ruter-ds/rds-scss-core/src/scss/01-config/manifest-config';
.components-home {
  p + p {
    margin-top: 1rem !important;
  }

  h1 {
    margin-bottom: 0px;
  }
  .tickets-image {
    margin-top: 38px;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .tickets {
    text-align: center;
  }
  button{
    margin-top: 1.5rem;
  }
  a {
    margin-top: 0.7rem;
  }
  .products {
    div:nth-child(even) {
      // every other product
      flex-direction: row-reverse;
    }
    .product {
      margin-top: 80px;
      .image {
        text-align: center;
      }
      .text {
        text-align: left;
      }
      img {
        margin-top: 25px;
        max-width: 80%;
      }
    }
  }
  @include mq($until: s) {
    .tickets-image {
      img {
        max-width: 300px;
      }
    }
    .products {
      div:nth-child(even) {
        // every other product
        flex-direction: row;
      }
      .product {
        margin-top: 40px;
        .text {
          text-align: center;
        }
        img {
          max-width: 300px;
        }
      }
    }
  }
}