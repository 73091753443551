.components-common-form-zonefield {
  margin-bottom: 1rem;

  legend {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .rds-field-message {
    margin-top: 1rem;
  }

  fieldset+fieldset {
    margin-top: 1rem;
  }

  select {
    margin-bottom: 10px;
    width: 200px;
  }
}

.rds-form-group + .components-common-form-zonefield {
  margin-top: 1rem;
}