@import '../../../../node_modules/@ruter-ds/rds-scss-core/src/scss/00-vendor/manifest-vendor';
@import '../../../../node_modules/@ruter-ds/rds-scss-core/src/scss/01-config/manifest-config';
.components-agreement-info-info {
  video {
    width: 100%;
  }
  li {
    font-size: 1rem;
    line-height: 1.3125rem;
  }
  a {
    margin-top: 20px;
  }
  table {
    td:nth-child(n+2) {
      text-align: right;
    }
    th:nth-child(n+2) {
      text-align: right;
    }
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-bottom: 2rem;
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}