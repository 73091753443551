.components-ticketcounter-order {
  padding-top: 20px;

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }

  .section-label span {
    font-weight: 500;
  }
}