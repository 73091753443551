@import "~@ruter-ds/rds-fonts/dist/styles/rds-fonts.css";
@import "~@ruter-ds/rds-scss-core/dist/styles/rds-core.css";

html,
body {
  display: flex;
  flex-flow: column nowrap;
}

#app {
  display: flex;
  flex-flow: column nowrap;

  &>div {
    display: flex;
    flex-flow: column nowrap;
  }

  h1 {
    margin-top: 1.8125rem;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: 500;
  }

  h2 {
    margin-top: 2rem;
    font-size: 1.375rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.3125rem;
    margin-top: 0px !important;
  }

  .rds-pagination {
    margin-top: 20px;
  }
}

#ruter-bedrift-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  main {
    flex: 1 1 auto;
  }
}

body {
  background-color: #f5f5f5;
}

.Toastify {
  .toast-container {
    top: 6rem;
  }

  .toast {
    border: 1px solid lightgray;
    color: #262626;
    font-family: "Tid, Arial, sans-serif";
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 25px;
    background-color: white;
    border-bottom: 3px solid #5d8200;

    button {
      color: #262626;
    }

    &.Toastify__toast--error {
      border-bottom: 3px solid #d60606;
    }
  }
}

.mt-2 {
  margin-top: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.DayPicker {
  thead {
    border: none
  }

  td,
  th {
    border: none;
    padding: 0;
  }
}