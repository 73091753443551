.components-freeticketagreement-massorders {
  .buttons {
    text-align: right;
    margin-bottom: 1rem;
    width: fit-content;
    margin-left: auto;
    margin-top: 0;
  }

  .wcat-progress-radial {
    margin-left: 1rem;
  }
}