.components-schoolticketdetails {
  .tickets {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .student-info {
    font-size: 1rem;
    display: flex;
    align-items: center;
    .label {
      font-weight: 500;
      margin-right: 10px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }

      &::after {
        content: ":";
      }
    }
  }
}
