@import '~@ruter-ds/rds-tokens/dist/scss/tokens';

$subMenuBackgroundColor: $color-anthracite-40;
$subMenuActiveBackgroundColor: $color-anthracite-60;

.sub-menu {
  background-color: #5B6067;
  height: 2rem;

  &>* {
    display: flex;
  }

  a {
    color: white;
    font-size: 1rem;
    text-decoration: none;
    padding: 0 20px;
    line-height: 1.875rem;
    display: flex;
    align-items: center;
    line-height: 2rem;

    .text:hover {
      text-decoration: underline;
      color: white;
    }

    &.active {
      // background-color: $subMenuActiveBackgroundColor;
      font-weight: bold;
    }

    &:first-child {
      margin-left: -20px;
    }

    .badge {
      display: none;
    }

    &.focused {
      .badge {
        display: block;
        height: 15px;
        width: 14px;
        background-color: #F04A3A;
        border-radius: 9px;
        margin-left: 4px;
        margin-top: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        font-weight: normal;
        padding: 1px 1px 0 0;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (max-width: 880px) {
    display: none;
  }
}
