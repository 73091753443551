.components-agreement-freeticket-travelcard {
  background-color: white;
  margin-right: 40px;
  margin-bottom: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 390px;
  flex-shrink: 0;
  .employee-name {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    overflow: hidden;

    .value {
      display: inline-block;
      background-color: #eeeef0;
      border-radius: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      padding: 0 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90%;
    }
  }

  .status {
    height: 5px;
    width: 100%;
    &.active {
      background-color: #76a300;
    }
    &.expired {
      background-color: #e60000;
    }
    &.cancelled {
      background-color: #e60000;
    }
    &.failed {
      background-color: #e60000;
    }
    &.pending {
      background-color: #ffc800;
    }
  }
  .info {
    padding: 5px 0;
    .row {
      display: flex;
      padding: 7px 15px;
      font-size: 1rem;
      .label {
        font-weight: 500;
      }
      .value {
        margin-left: auto;
      }
    }
  }
  .actions {
    display: flex;
    border-top: 1px solid #dddde1;
    button {
      flex-grow: 1;
      line-height: 0.875rem;
      padding: 10px 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: none;
      font-size: 0.75rem;
      color: #262626;
      transition: background-color 0.5s ease;
      svg {
        color: #5a6068;
      }
      span:first-child {
        margin-bottom: 5px;
      }
      &:hover {
        background-color: #f5f5f5;
      }
      &:disabled {
        color: lightgray;
        cursor: not-allowed;
        svg {
          color: lightgray;
        }
        &:hover {
          background-color: inherit;
        }
      }
      .rds-spinner--small {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
