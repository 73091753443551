.components-terms-ruterbedrift {
  p+p {
    margin-top: 1rem !important;
  }

  ul {
    margin-top: 1rem !important;
    margin-bottom: 1rem;

    li {
      margin-top: 0.3rem !important;
    }
  }
}