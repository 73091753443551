.components-common-form-productpricefield {
  border: 1px solid;
  padding: 10px 17px;
  margin-bottom: 4rem;
  margin-top: 1.5rem;
  background-color: rgb(255, 255, 255);
  border-color: rgb(117, 117, 117);

  &.gray {
    background-color: #F5F5F5;
    width: 235px;
  }

  .price {
    font-weight: bold;
    font-size: 2rem;
    margin-right: 0.125rem;
  }

  .label {
    font-weight: 500;
    font-size: 1rem;
  }
}