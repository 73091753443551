@import '../skeleton/common.scss';

$marginTop: 1rem;
$marginBottom: 0.5rem;
$fontSize: 2rem;
$lineHeight: 3.75rem;

#app {
  h2 {
    margin-top: $marginTop !important;
    margin-bottom: $marginBottom;
    font-weight: 500;
  
    &.skeleton {
      width: 50%;
      height: $fontSize;
      margin-top: $marginTop + ($lineHeight - $fontSize) / 2;
      margin-bottom: $marginBottom + ($lineHeight - $fontSize) / 2;
      
      @include skeleton-style();
    }
  }
}

