.status-column {
  display: inline-flex;
  align-items: center;

  .status-icon {
    width: 8px;
    height: 8px;
    background-color: gray;
    border-radius: 4px;
    margin-right: 10px;

    &.active {
      background-color: #76A300;
    }

    &.expired {
      background-color: #E60000;
    }

    &.pending {
      background-color: #FFC800;
    }

    &.cancelled {
      background-color: #E60000;
    }
  }
}
