.component-ticketcounter-whitelist {
  .new-user {
    margin-bottom: 0.5rem;
  }

  .actions {
    display: flex;
    gap: 0.5rem;
  }

}