.components-app-loadingerror {
  text-align: center;

  img {
    margin-bottom: -100px;
    margin-top: 0px;
  }

  @media only screen and (max-width: 550px) {
    img {
      margin-top: -50px;
    }
  }


  @media only screen and (max-width: 350px) {
    img {
      width: 80%;
      margin-bottom: -80px;
    }
  }
}