$filter-label-width: 150px;
$filter-input-width: 321px;
$filter-row-width: $filter-label-width + $filter-input-width;


.production-list {
  .show-orders {
    margin-top: 0;
  }

  .filter {
    margin-bottom: 2rem;

    .date-search {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      .dash {
        margin: 0 1rem;
      }

      label {
        width: $filter-label-width
      }

      .date-label {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }

    .dropdown-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      label {
        width: $filter-label-width
      }

      .datalist {
        width: $filter-input-width;
      }
    }

    .clear-filter {
      width: $filter-row-width;
      text-align: right;
    }
  }

  .date-validation-message {
    width: $filter-input-width;
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    margin-left: $filter-label-width;
  }
}