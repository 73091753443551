.goto-column {
  width: 25px;
  padding: 0 !important;
  svg {
    color: #75828E !important;
  }
}

@media only screen and (max-width: 600px) {
  .goto-column {
    display: none !important;
  }
}
