.components-common-buttons-buttonlink {
  background: none;
  border: none;
  color: #0874BF;
  text-decoration: underline;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  
  &:hover {
    text-decoration: none;
  }
}