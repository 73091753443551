.components-user-admin-users {
  .search-and-new {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;

    .wcat-input-container {
      width: 400px
    }

    button {
      margin-left: auto;
    }
  }

  .user-state-column {
    display: inline-flex;
    align-items: center;

    .status-icon {
      width: 8px;
      height: 8px;
      background-color: gray;
      border-radius: 4px;
      margin-right: 10px;

      &.active {
        background-color: #76A300;
      }

      &.deactive {
        background-color: #E60000;
      }
    }
  }

  .actions {
    padding: 0;
    text-align: right;

    button {
      background: #F8F8F8;
      border: 1px solid #DDDDE1;
      border-radius: 2px;
      color: #262626;
      width: 40px;
      height: 32px;
      overflow: hidden;
    }

    button {
      margin: 10px 5px;
    }

    button:first-child {
      margin-left: 0px;
    }

    button:last-child {
      margin-right: 10px;
    }
  }
}

.delete-user-modal,
.reactivate-user-modal {
  .rds-btn-group {
    margin-top: 1rem;
  }
}