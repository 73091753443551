
.components-freeticketagreement-masschangedepartment {
  .open-confirm-modal-button{
    margin-top: 1rem;
  }

  .no-tickets {
    margin-top: 2rem;
  }

  .summary {
    background-color: white;
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-top: 30px;

    .row {
      display: flex;

      &.bold {
        font-weight: 800;
      }
      &.red {
        .value {
          color: red;
        }
        
      }

      .value {
        text-align: right;
        flex-grow: 1;
      }
    }
  }

  .file-header {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
  }

  input[type="file"] {
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input[type="file"] + label {
    margin-top: 20px;
    display: block;
  }

  input[type="file"] + label, .file-example {
    color: #0874bf;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }

  .header-row {
    display: flex;
    margin-bottom: 1rem;

    .file-name {
      background-color: #eeeef0;
      border-radius: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      padding: 0 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1rem;
    }

    .remove-file {
      background: none;
      border: none;
      padding: 0;
      color: #d60606;
      text-decoration: underline;
      cursor: pointer;
      margin-left: auto;
    }
  }
}

.mass-change-department-confirm-modal {
  .status-radial {
    margin-left: 0.5rem;
  }
}
