.components-freeTicketAgreement-massorder {
  input[type="file"] {
    clip: rect(0, 0, 0, 0);
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input[type="file"]+label {
    color: #0874bf;
    text-decoration: underline;
    cursor: pointer;
  }

  .file-header {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .file-example {
    color: #0874bf;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }

  .narrow {
    max-width: 450px;
  }

  .rds-svg--tick-icon {
    color: green;
    margin-right: 10px;
    display: inline-block;
    margin-top: -1px;
  }

  .rds-svg--questionmark-circle-icon {
    color: red;
    cursor: pointer;
  }

  .invalid-rows-help-text {
    margin-bottom: 1rem;
  }

  .surround-error {
    color: lightgray;
  }

  .explain-error-button {
    background: none;
    border: none;
    padding: 0;
    margin-right: 0.2rem;
  }

  .file {
    margin-top: 0rem;
    padding: 1rem;
    border-radius: 2px;
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    .header-row {
      display: flex;
      margin-bottom: 1rem;

      .file-name {
        background-color: #eeeef0;
        border-radius: 1rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        padding: 0 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1rem;
      }

      .remove-file {
        background: none;
        border: none;
        padding: 0;
        color: #d60606;
        text-decoration: underline;
        cursor: pointer;
        margin-left: auto;
      }
    }

    .order-button {
      margin-top: 1rem;
    }
  }
}

.mass-order-error-explan-modal {
  .surround-error {
    color: lightgray;
  }

  .error-row {
    display: flex;

    .label {
      width: 120px;
      font-weight: 500;
      flex-shrink: 0;

      &::after {
        content: ':';
      }
    }

    .value {
      overflow-wrap: anywhere;
    }
  }
}