.components-companyagreement-widget {
  display: flex;

  h2{
    font-weight: 500;
    font-size: 1.125rem;
    margin-top: 1rem;
  }

  .code-card {
    textarea {
      height: 185px;
      width: 100%;
      padding: 0.5rem;
      word-break: break-all;
      border: none;
      resize: none;
      line-height: 1.25rem;
    }
  }

  .configuration {
    width: 520px;
    padding-right: 80px;
    border-right: 1px solid #DADADA;
    margin-right: 80px;
  }

  .widget {
    width: 600px;
  }

  .buttons {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }

  .skeleton {
    .h1 {
      width: 300px;
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      height: 1.5rem;
      margin-top: 3rem;
      color: transparent;

      &+.h1 {
        margin-top: 2.25rem;
      }
    }
    .h2 {
      width: 250px;
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      height: 1rem;
      margin-top: 3rem;
      color: transparent;
    }
    .text {
      width: 200px;
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      height: 1rem;
      color: transparent;
      margin: 0.5rem 0;
    }
    .block {
      width: 250px;
      height: 200px;
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      color: transparent;
      margin: 0.5rem 0;
    }
  }
}
