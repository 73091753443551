.components-layout-mobilmenu {
  display: none;
  height: 100%;
  align-items: flex-end;
  color: white;

  button {
    background: none;
    border:none;
    cursor: pointer;
  }

  .toggle-button {
    background: none;
    border: none;
    display: block;
    float: left;
    outline: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 8px;
    .menu-text {
      margin-top: -4px;
    }
    div {
      font-size: 0.625rem;
      line-height: 0.625rem;
      text-transform: uppercase;
      color: white;
    }
    img {
      height: 19px;
      width: 27px;
    }
    &.open {
      background: white;
      margin-bottom: 0px;
      width: 44px;
      .rds-icn {
        width: 18px;
        svg {
          width: 18px;
          color: #333941;
        }
      }
      div {
        margin-top: -5px;
        color: #333941;
        padding-bottom: 10px;
      }
    }
  }
  .menu-content {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
    margin-top: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.11);
    min-width: 200px;
    top: 49px;
    .toggle-expand {
      margin: 0 !important;
      padding: 10px 20px !important;
      color: black;
      display: flex;
      border-bottom: 1px solid #EDEDED;
      align-items: center;
      span {
        margin-left: auto;
        color: #5b6067;
        margin-right: 10px;
      }
    }
    a,
    button {
      margin: 0 !important;
      padding: 10px 20px !important;
      color: black;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #EDEDED;
      text-align: left;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.active {
        background-color: #d7d8d9;
        color: #252525;
      }
    }
    .item-group {
      display: flex;
      flex-direction: column;
      .sub-items {
        display: none;
        flex-direction: column;
        margin-left: 0 !important;
        a {
          padding-left: 40px !important;
        }
      }
    }
    .open.item-group .sub-items {
      display: flex;
    }
  }
  @media only screen and (max-width: 880px) {
    display: flex;
  }
}
