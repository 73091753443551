.components-app-loading-loading{
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {

  }

  .text {
    margin-left: 20px;
    font-weight: 500;
    font-size: 1.5rem;
  }
}
