.components-layout-dropdownmenu {
  cursor: pointer;
  position: relative;
  display: block !important;
  align-self: center;
  margin-left: auto;

  .toogle-button {
    display: flex;
    flex-direction: row;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    cursor: pointer;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }

    .first-name {
      display: none;
    }

    &:hover {

      .first-name,
      .first-and-company-name {
        text-decoration: underline;
      }
    }

    div {
      margin-left: 5px !important;
    }
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    position: absolute;
    right: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.11);
    min-width: 220px;
    margin-top: 7px;
    color: black;

    a,
    button {
      padding: 10px 20px 10px 33px !important;
      background: none;
      border: none;
      color: black;
      padding: 0;
      text-align: left;
      margin: 0 !important;
      cursor: pointer;

      &:hover {
        background-color: #F5F5F5;
      }
    }

    .companies {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #DDDDE1;
      border-top: 1px solid #DDDDE1;

      .selected-icon {
        display: none;
      }

      button.selected {
        display: flex;
        align-items: center;
        padding-left: 33 !important;

        .selected-icon {
          display: inline-block;
          margin-right: 10px;
        }

        .company-name {
          margin-left: 0 !important;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 50%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: white;
      border-top: 0;
      margin-left: -7px;
      margin-top: -7px;
    }
  }

  .profile {
    border-bottom: 1px solid #DDDDE1 !important;

    .profile-icon {
      margin-right: 10px;
    }
  }

  .logout-icon {
    margin-right: 10px;
  }

  .simple-view {
    button {
      padding-left: 66px !important;

      &.selected {
        padding-left: 33px !important;
      }
    }
  }

  .search-view {
    min-width: 400px;

    .selected-company {
      border-bottom: 1px solid #DDDDE1;
      background-color: #DDDDE1;
      padding: 20px 10px 20px 33px;
      cursor: default;

      .header {
        font-weight: 500;
      }

      .value {}
    }

    .search {
      padding: 20px 33px 20px 33px;
      cursor: default;
    }

    .search-result {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      height: 480px;
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .no-result {
      padding: 10px 10px 20px 38px;
      color: red
    }
  }

  @media only screen and (max-width: 1060px) {
    .toogle-button {
      flex-direction: column;
      justify-content: center;

      div {
        margin-left: 0 !important;
      }

      .first-name {
        display: block;
        text-transform: uppercase;
        font-size: 0.625rem;
        line-height: 0.625rem;
      }

      .first-and-company-name {
        display: none;
      }

      img {
        margin-bottom: -4px;
      }
    }

    .dropdown-content {
      &::after {
        right: 10px;
      }
    }
  }
}