.components-companyagreement-employeetickets-skeletonticket {
  @keyframes pulse {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.3;
    }
  }
  
  width: 290px;
  background-color: white;
  margin-right: 40px;
  margin-bottom: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .info {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 15px;

    .info-line {
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      height: 1rem;
    }
  }

  .actions {
    border-top: 1px solid #DDDDE1;
    height: 56px;
    justify-content: space-around;
    align-items: center;
    display: flex;

    .action {
      background-color: #E3E6EC;
      animation: pulse 0.8s ease-in-out infinite alternate;
      height: 32px;
      flex-grow: 0.3;
    }
  }
}