.top-menu {
  background-color: #333941;
  color: white;

  &.brakar {
    background-color: #f3d03e;
    color: #333941;
  }

  &.akt {
    background-color: #003d4c;
  }

  & > div {
    align-items: center;
    display: flex;
    height: 4.5rem;
  }

  .logo {
    margin-right: 40px;
    line-height: 0;
    img {
      width: 150px;
    }
  }

  a,
  button,
  .text {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }

  .desktop-visible {
    height: 100%;
    display: flex;
    flex-grow: 1;
  }

  .mobile-visible {
    height: 100%;
    display: flex;
    flex-grow: 1;
  }

  @media only screen and (max-width: 880px) {
    & > div {
      height: 3.5rem;
    }
  }
}
