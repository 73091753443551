.create-company-page {
  .agreement-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &>button {
      margin-left: auto;
    }
  }
}