.components-layout-desktopmenu {
  margin: 0;
  flex-grow: 1;
  display: flex;
  height: 100%;

  &>* {
    display: flex;
    outline: none;
    padding: 0 17px;
    font-size: 1.125rem;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    align-items: center;
    margin-left: 0 !important;

    &.right-align {
      margin-left: auto !important;
    }

    &.active {
      font-weight: bold;
    }
  }

  .badge {
    display:none;
  }

  .focused {
    .badge {
      display: block;
      height: 15px;
      width: 14px;
      background-color: #F04A3A;
      border-radius: 9px;
      margin-left: 4px;
      margin-top: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 8px;
      font-weight: normal;
      padding: 1px 0px 0 0;
      color: white;
    }
  }

  @media only screen and (max-width: 880px) {
    display: none;
  }
}
