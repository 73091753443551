.link-page {
  .code-card {
    width: 600px;

    textarea {
      height: 100px;
      width: 100%;
      padding: 0.5rem;
      word-break: break-all;
      border: none;
      resize: none;
      line-height: 1.25rem;
    }
  }

  .buttons {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }
}
