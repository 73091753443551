@import './common.scss';

.input-skeleton {
  overflow: hidden;

  .label {
    @include skeleton-style();
    height: 0.8125rem;
    width: 200px;
    margin-bottom: 0.75rem;
    margin-top: 0.4375rem;
  }

  .input {
    @include skeleton-style();
    height: 2.75rem;
    width: 100%;
  }
}
.input-skeleton + .input-skeleton {
  margin-top: 1rem;
}
