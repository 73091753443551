@import '../skeleton/common.scss';

$fontSize: 1.125rem;
$lineHeight: 1.75rem;

.components-common-detail {
  display:flex;

  .label {
    font-weight: 500;
  }

  .label::after {
    content: ':';

  }

  .value {
    margin-left: auto;
  }

  &.skeleton {
    .inner {
      height: $fontSize;
      margin: ($lineHeight - $fontSize) / 2 0;
      width: 100%;
      @include skeleton-style();
    }
    
  }
}