.components-app-header-signinbutton {
  margin-left: auto;
  outline: none;
  padding: 0 17px;
  font-size: 1.125rem;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  span {
    display: none;
  }
  @media only screen and (max-width: 880px) {
    padding: 0;
    align-self: center;
    margin-top: -4px;
    text-align: center;
    text-decoration: none;
    div {
      font-size: 0.625rem;
      line-height: 0.625rem;
      text-transform: uppercase;
    }
    span {
      display: inline-block;
    }
  }
}