.components-app-selectcomany-selectcompany {
  h1 {
    text-align: center;
    margin-top: 40px;
  }

  button {
    width: 100%;
    background: white;
    border: 1px solid #DDDDE1;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:last-child {
      border-top: 0px;
    }
  }
}
