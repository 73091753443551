.free-ticket-info-page {
  ul {
    margin-top: 1rem !important;
    margin-bottom: 1rem;
    li {
      margin-top: 0rem !important;
      font-size: 1rem;
    }
  }

  a  {
    font-size: 1rem;
    line-height: 3rem;
  }
}