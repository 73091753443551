.components-ticketcounter-order-details {
  .table-container {
    margin-top: 20px;
  }

  .ticket-count {
    .ticket-count-label {
      margin-right: 1rem;
    }

    .rds-progress-radial {
      margin-top: -2px;
    }
  }

  .row {
    display: flex;

    .label {
      font-weight: 500;
      margin-right: 10px;

      &::after {
        content: ':'
      }
    }
  }
}

.cancel-pending-ticket-modal {
  .error-message {
    margin-top: 1rem;
  }

  .button-group {
    margin-top: 1rem;
  }
}

.components-agreement-ticket-ticket {
  background-color: white;
  margin-right: 40px;
  margin-bottom: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 290px;

  .status {
    height: 5px;
    width: 100%;

    &.active {
      background-color: #76A300;
    }

    &.expired {
      background-color: #E60000;
    }

    &.cancelled {
      background-color: #E60000;
    }

    &.pending {
      background-color: #FFC800;
      ;
    }
  }

  .info {
    padding: 5px 0;

    .row {
      display: flex;
      padding: 7px 15px;
      font-size: 1rem;

      .label {
        font-weight: 500;
      }

      .value {
        margin-left: auto;
      }
    }
  }
}