.components-housing-unit-costs {
  .download {
    text-align: right;
    margin-bottom: 0.5rem;
  }

  .old-value {
    margin-right: 10px;
    text-decoration: line-through red;
  }

  .price {
    text-align: right;
  }

  .price-prefix {
    margin-right: 10px;
  }

  th{
    text-transform: lowercase;

    &::first-letter{
      text-transform: uppercase;
    }
  }
}