.components-common-form-zonefield {
  margin-top: 1rem;
}

.components-common-form-productpricefield {
  border: 1px solid gray;
  padding: 10px 17px;
  margin-bottom: 4rem;
  margin-top: 1.5rem;
  background-color: white;
  .price {
    font-weight: bold;
    font-size: 2rem;
    margin-right: 0.125rem;
  }

  .label {
    font-weight: 500;
    font-size: 1rem;
  }
}
