.components-schoolticketagreement-schooltickets {
  .search-field {
    margin-bottom: 2rem;
    max-width: 440px;

    &>a {
      margin-left: 1.5rem;
    }
  }


  .filter-and-download {
    display: flex;
    margin-bottom: 0.5rem;

    .filter {
      display: flex;
      align-items: center;

      label {
        margin-right: 0.5rem;
      }
    }


    .download {
      display: flex;
      align-items: end;
      text-align: right;
      margin-left: auto;

      button {
        margin-left: 1rem;
      }
    }
  }

  tr {
    cursor: pointer;
  }

  .mobile {
    display: none;

    @media (max-width: 600px) {
      display: table-cell;
    }
  }

  .desktop {
    display: none !important;

    @media (min-width: 599px) {
      display: table-cell !important;
    }
  }

  th.ticket-carrier {
    @media (min-width: 599px) {
      width: 230px;
    }
  }

  th.class {
    @media (min-width: 599px) {
      width: 100px;
    }
  }
}