.components-freeticketagreement-freetickets {
  .search-and-buttons {
    display: flex;
    margin-bottom: 2rem;
    gap: 1.5rem;

    &>div {
      flex-grow: 1;
    }

  }

  tr {
    cursor: pointer;
  }

  .download {
    text-align: right;
    margin-bottom: 0.5rem;

    button {
      margin-left: 1rem;
    }
  }
}