.components-ticketcounter-order-details {
  .order-incomplete-warning {
    color: red;
    float: left
  }

  .table-container {
    margin-top: 20px;

    .download-button-container {
      display: flex;
      flex-direction: row-reverse;

      .error-message {
        color: #8B0000;
        font-size: 14px !important;
      }

    }
  }

  .ticket-count {
    .ticket-count-label {
      margin-right: 1rem;
    }

    .rds-progress-radial {
      margin-top: -2px;
    }
  }

  .actions {
    width: 130px;
    text-overflow: unset;
    padding: 0;

    .group {
      margin-top: 0;
    }
  }

  .row {
    display: flex;

    .label {
      font-weight: 500;
      margin-right: 10px;

      &::after {
        content: ':'
      }
    }
  }
}

.cancel-pending-ticket-modal {
  .error-message {
    margin-top: 1rem;
  }

  .button-group {
    margin-top: 1rem;
  }
}