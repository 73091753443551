.components-contactus {
  .data-row {
    display: flex;
    .label {
      font-weight: 500;
      width: 100px;

      &::after {
        content: ':'
      }
    }

    data {
      white-space: pre;
    }
  }
}