.components-freeticketagreement-taxbasechanges {
  .price-column {
    text-align: right;
  }

  .old-price+.new-price {
    margin-left: 10px;
  }

  .old-price {
    text-decoration: line-through red;
  }

  .filter {
    margin-bottom: 0rem;
    display: flex;
    align-items: flex-end;

    .inputs {
      width: 300px;
      margin-bottom: 1.5rem;
    }

    .download {
      margin-left: auto;
      margin-bottom: 0.5rem;
    }
  }
}