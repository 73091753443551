.components-ticketcounter-order-history {
  table {
    margin-top: 1.5rem;

    tr {
      cursor: pointer;
    }
  }

  .ticket-count {
    display: flex;

    .rds-progress-radial {
      margin-left: auto;
      margin-top: -2px;
    }
  }
}
