.components-useradmin-user-rolesinput {
  margin-top: 2rem !important;

  .header-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    span {
      font-weight: 500;
    }

    button {
      margin-left: auto;
    }
  }

  .actions {
    padding: 0;

    button {
      background: #f8f8f8;
      border: 1px solid #dddde1;
      border-radius: 2px;
      color: #262626;
      width: 40px;
      height: 32px;
      overflow: hidden;
    }

    button+button {
      margin: 10px;
    }
  }
}

.company-roles-modal {
  .rds-base-modal__content {
    min-height: 300px;
  }

  .search-row {
    margin-bottom: 1rem;
  }

  .company-row {
    display: flex;

    .label {
      font-weight: 500;
      margin-right: 2rem;
      width: 170px;

      &:after {
        content: ":";
      }
    }
  }

  .search-result {
    display: flex;
    flex-direction: column;
    height: 480px;
    overflow-y: auto;
    scrollbar-width: thin;

    & button {
      background: none;
      border: none;
      color: black;
      padding: 10px 20px 10px 10px;
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: #f5f5f5;
        text-decoration: underline;
      }
    }
  }
}