.cta-link {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #0874bd;

  &:hover {
    color: #002b79;
    text-decoration: underline;

    .icon-left {
      transform: translateX(4px);
    }
  }

  .icon-left {
    margin-right: 0.25rem;
    transition: transform .3s ease-out
  }
}