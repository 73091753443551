.company-admin-company-page {
  .actions {
    display: flex;
    gap: 0.5rem;
  }

  .agreement-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &>button {
      margin-left: auto;
    }
  }

  .delete-company-button {
    margin-top: 2rem;
  }
}