.components-ticketcounter-ticket-history {
  .search-and-new-ticket {
    display: flex;

    input {
      width: 440px;
    }

    button {
      margin-left: auto;
    }
  }

  table {
    margin-top: 1.5rem;
  }
}
