.edit-company-address-modal {
  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 3rem;
  }

  .zip-code-and-area {
    display: flex;
    gap: 2rem;

    .zip-code {
      width: 200px;
    }

    .area {
      flex-grow: 1;
    }

    .wcat-form-group {
      margin-top: 1rem
    }
  }
}