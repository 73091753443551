.components-ticket-info-ticketinfo {
  a {
    margin: 0.75rem 0;
  }

  .rds-card {
    margin-top: 2rem;
    padding: 5px 30px;

    ul {
      margin-top: -1rem;
      margin-bottom: 2rem;
    }

    p {
      margin: 0.5rem 0 1.5rem;
      padding: 0;
    }
  }

  .label {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 3.25rem;
    margin-bottom: 0.5rem;

    h2 {
      margin-bottom: 1rem;
    }

    @media(max-width: 490px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  th.actions {
    opacity: 0.001;
    width: 70px;
  }

}