.ticket-request-page {
  .actions {
    width: 130px;
    text-overflow: unset;
    padding: 0 2px;

    button {
      background: #F8F8F8;
      border: 1px solid #DDDDE1;
      border-radius: 2px;
      color: #262626;
      width: 40px;
      height: 32px;
      overflow: hidden;

      &:disabled {
        color: lightgray;
      }
    }

    button+button {
      margin: 10px;
    }
  }

  .expired {
    color: red;
  }

  th {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
