@import "../../common/skeleton/common.scss";

.freeticket-settings-page {
  .auto-end-children-tickets-skeleton {
    width: 400px;
    height: 2.75rem;
    margin-top: 0.75rem;
    @include skeleton-style();
  }

  .question-container {
    margin-top: 2rem;

    .rds-form-group {
      margin-right: 0.5rem;
      display: inline-block;
      line-height: 1.75rem;
    }

    .label {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-right: 0.5rem;
      display: inline-block;
    }

    .rds-select--medium {
      height: 1.75rem;
    }
  }
}
