.components-ticket-counter-info {
  li {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  dl {
    flex-direction: column;

    dt {
      font-size: 1rem;
      max-width: none !important;
      margin: 0 !important;
    }

    dd {
      font-size: 1rem;
      padding-left: 0 !important;
    }

    dd:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }


  h3 {
    font-size: 18px !important;
  }

  h3+ul {
    margin-top: 1rem !important;
  }

  .title {
    margin-top: 1.5rem !important;
  }

}