.components-info-card {
  padding: 1rem;

  .header {
    display: flex;
    margin-bottom: 1.5rem;

    h2 {
      margin-top: 0;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: bold;
    }

    button {
      margin-left: auto;
      line-height: 1.25rem;
    }
  }

  .body {
    .info {
      display: flex;

      .label {
        width: 250px;
        font-weight: 500;
      }
    }

    .info+.info {
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: 600px) {
    .body .info {
      flex-direction: column;
    }
  }
}

.components-info-card+.components-info-card {
  margin-top: 2rem;
}