.components-freeticket-travelcard {
  .REQUESTED {
    background-color: #ffc800;
  }

  .CREATED {
    background-color: rgb(255, 81, 0);
  }

  .actions {
    width: 130px;
    text-overflow: unset;
    padding: 0;
    text-align: center;

    button {
      background: #F8F8F8;
      border: 1px solid #DDDDE1;
      border-radius: 2px;
      color: #262626;
      width: 40px;
      height: 32px;
      overflow: hidden;
    }

    button + button {
      margin: 10px;
    }
  }
}
